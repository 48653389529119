<script setup lang="ts">
import type { UtilsGoBackProps } from './UtilsGoBack.props'

const props = defineProps<UtilsGoBackProps>()
</script>

<template>
  <NuxtLink
    v-bind="props"
    class="text-neutral-black text-book-6 inline-flex items-center gap-1 py-1"
    :tabindex="0"
  >
    <DefaultIconsChevronLeft
      class="h-4 w-4"
      width="16"
      height="16"
      aria-hidden="true"
    />
    <slot />
  </NuxtLink>
</template>
